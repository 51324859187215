<template>
<div>
  <b-container fluid class="mb-5">
    <b-row class="py-4">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Data model</h1>
        <p>Explanation of the data model behind the ESG Metrics application</p>
      </b-col>
    </b-row>
    <b-row class="shadow bg-white mb-5">
      <b-col class="py-4">
        <p>The graph sets out entities and relationships implemented in the ESG Metrics app.</p>
        <p><strong>Pillars</strong> and <strong>Categories</strong> provide a thematic taxonomy to help organise metrics.</p>
        <p>Below are high level statistics for each key entity and links to explore them further:</p>
        <ul>
          <li><router-link :to="{ name: 'MetricsReportingframeworks' }" class="link-card">Frameworks: {{ reportingframeworks.length?reportingframeworks.length:8 }}</router-link></li>
          <li><router-link :to="{ name: 'Metrics' }" class="link-card">Metrics: {{ metrics.length }}</router-link></li>
          <li><router-link :to="{ name: 'DataElements' }" class="link-card">Data elements: {{ dataelements.length }}</router-link></li>
        </ul>
      </b-col>
      <b-col class="py-4">
        <div id="mermaid" v-html="graph"></div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import delay from '@/libs/delay'
import mermaid from 'mermaid'

export default {
  name: 'Interoperability',
  components: {
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    reportingframeworks: function () {
      return this.$store.state.reportingframeworks
    }
  },
  created: function () {
  },
  mounted: function () {
    this.draw()
  },
  data () {
    const data = {
      graph: `
        erDiagram
          Category }o--|| Pillar : manyToOne
          Metric }o--o{ DataElement : manyToMany
          Framework }o--o{ Metric : manyToMany-reference
          Metric }o--|| Category : manyToOne 
          Pillar {
            example name "Environmental"
          }
          Category {
            example name "Air quality"
          }
          Framework {
            example name "SASB"
          }
          Metric {
            example name "Air emissions for NO"
          }
          DataElement {
            example name "NOx emissions (excluding N2O)"
          }
      `
    }
    return data
  },
  methods: {
    draw: async function () {
      await delay(50)
      mermaid.initialize({ theme: 'default', startOnLoad: false, securityLevel: 'loose' })
      const container = document.getElementById('mermaid')
      mermaid.init(this.graph, container)
    }
  },
  watch: {
  }
}
</script>
